import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Switch } from 'antd';
import Title from 'antd/lib/typography/Title';

import { Div } from '../../../framework';
import { setFlyoutOpen } from '../../../store/app.slice';
import { setPartialSettings } from '../../../store/user.slice';
import { RootState } from '../../../store/store';
import { useSettingsService } from '../../../service/settings.service';

const SettingsPage: FunctionComponent = () => {
  const { settings } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const settingsService = useSettingsService();

  useEffect(() => {
    dispatch(setFlyoutOpen({ isOpen: false }));
  }, []);

  const handleToggleTelegramNotification = async (checked: boolean) => {
    try {
      const updatedValues = { isTelegramNotificationOn: checked };
      await settingsService.updateSettings(updatedValues);
      dispatch(setPartialSettings(updatedValues));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Div ph='l' style={{ width: '100%' }}>
      <Title level={3}>Settings</Title>
      <Form>
        <Form.Item
          name='telegram'
          label='Telegram notification'
          labelAlign='left'
          labelCol={{ flex: 'auto' }}
          wrapperCol={{ flex: 'none ' }}
        >
          <Switch
            disabled={!settings.hasActivateTelegram}
            checked={
              settings.hasActivateTelegram && settings.isTelegramNotificationOn
            }
            checkedChildren='ON'
            unCheckedChildren='OFF'
            onChange={handleToggleTelegramNotification}
          />
        </Form.Item>
      </Form>
    </Div>
  );
};

export default SettingsPage;
